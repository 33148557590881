import React, { useEffect, Fragment } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/bundle';

import {
  Layout,
  SEO,
  FormHero,
  Section,
  Container,
  Grid,
  Col,
  Flex,
  Overlay,
  AnchorText,
  SanityBlockContent,
  FadeIn,
} from '../components';

import { useSanity } from '../hooks';

export const query = graphql`
  query LocationTemplateQ(
    $slug: String
    $heroBgImgSlug: String
    $reviewImg1Slug: String
    $reviewImg2Slug: String
    $reviewImg3Slug: String
  ) {
    page: googlePagesSheet(slug: { eq: $slug }) {
      id
      pageType
      indexStatus
      canonical
      company
      phoneNumber
      city
      cityState
      cityStateZip
      stateAbb
      zip
      mapUrl
      mapEmbed
      keyword1
      keyword2
      keyword3
      keyword4
      keyword5
      audience
      slug
      heroBullet1
      heroBullet2
      heroBullet3
      heroBullet4
      heroBgImgSlug
      heroBgImgAlt
      reviewHeaderTagline
      reviewHeaderHeadline
      reviewHeaderParagraph
      reviewImg1Slug
      reviewImg1Alt
      reviewImg2Slug
      reviewImg2Alt
      reviewImg3Slug
      reviewImg3Alt
      residentialHeaderTagline
      residentialHeaderHeadline
      residentialHeaderParagraph
      commercialHeaderTagline
      commercialHeaderHeadline
      commercialHeaderParagraph
    }
    bgImg: allFile(
      filter: {
        relativeDirectory: { eq: "assets/images/locations/hero" }
        name: { eq: $heroBgImgSlug }
      }
    ) {
      nodes {
        id
        name
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: WEBP)
        }
      }
    }
    reviewImg1: allFile(
      filter: {
        relativeDirectory: { eq: "assets/images/locations/reviews/1" }
        name: { eq: $reviewImg1Slug }
      }
    ) {
      nodes {
        id
        name
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: WEBP)
        }
      }
    }
    reviewImg2: allFile(
      filter: {
        relativeDirectory: { eq: "assets/images/locations/reviews/2" }
        name: { eq: $reviewImg2Slug }
      }
    ) {
      nodes {
        id
        name
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: WEBP)
        }
      }
    }
    reviewImg3: allFile(
      filter: {
        relativeDirectory: { eq: "assets/images/locations/reviews/3" }
        name: { eq: $reviewImg3Slug }
      }
    ) {
      nodes {
        id
        name
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;

const StyledHeroHeadline = styled(motion.h1)`
  color: ${(props) => props.$headline};
`;

const StyledHeroSubheadline = styled(motion.div)`
  color: ${(props) => props.$subheadline};
`;

const StyledCheckIcon = styled.i`
  color: ${(props) => props.$color} !important;
  &:hover {
    color: ${(props) => props.$colorHover} !important;
  }
`;

const StyledContainer = styled((props) => <Container {...props} />)`
  color: ${(props) => props.$color} !important;
  background-color: ${(props) => props.$bgColor} !important;
  & h2 {
    color: ${(props) => props.$h2Color} !important;
  }
  & h3 {
    color: ${(props) => props.$h3Color} !important;
    :hover {
      color: ${(props) => props.$h3ColorHover} !important;
    }
    & span {
      color: ${(props) => props.$spanColor} !important;
    }
  }
`;

const StyledTagline = styled.span`
  color: ${(props) => props.$spanColor};
`;

const LocationTemplate = function (props) {
  const { data } = props;
  const page = data && data?.page;
  const bgImg = data && data?.bgImg?.nodes;
  const reviewImg1 = data && data?.reviewImg1?.nodes;
  const reviewImg2 = data && data?.reviewImg2?.nodes;
  const reviewImg3 = data && data?.reviewImg3?.nodes;

  const {
    siteSEO,
    info,
    colors,
    hero,
    primary,
    secondary,
    accent,
    neutral,
    asset,
    services,
  } = useSanity();

  const variants = {
    hidden: { opacity: 0, scale: 0.8, x: -100 },
    visible: { opacity: 1, scale: 1, x: 0 },
  };

  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
    if (!inView) {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const pageSEO = {
    title: `${page?.cityState} ${page?.keyword1}, ${page?.keyword2} & ${page?.keyword4}`,
    description: `Need ${page?.keyword1} & ${page?.keyword2} services in ${page?.cityState}? We offer affordable residential & commercial waste management. Call us today!`,
    canonical: page?.canonical,
    schema: page?.schema,
  };

  return (
    <Layout
      pageType={page?.pageType}
      navPhone={page?.phoneNumber}
      navAddress={page?.cityState}
      mapUrl={page?.mapUrl}
    >
      <SEO
        title={pageSEO?.title}
        description={pageSEO?.description}
        canonical={pageSEO?.canonical}
      >
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Maher Removal & Disposal",
              "image": "https://cdn.sanity.io/images/8au1ni7g/production/858b5cf1536d8adbed2674ce1bd85ab18d0b6b76-1000x227.png?w=441&h=100&auto=format",
              "@id": "https://www.maherremovaldisposal.com/${page?.slug}/",
              "url": "https://www.maherremovaldisposal.com/${page?.slug}/",
              "telephone": "${page?.phoneNumber}",
              "priceRange": "50-100",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "",
                "addressLocality": "${page?.city}",
                "addressRegion": "${page?.stateAbb}",
                "postalCode": "${page?.zip}",
                "addressCountry": "US"
              },
              "openingHoursSpecification": [{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday"
                ],
                "opens": "07:00",
                "closes": "16:00"
              },{
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Saturday",
                "opens": "08:00",
                "closes": "12:00"
              }],
              "sameAs": "https://www.facebook.com/maherremovaldisposal"
            }
          `}
        </script>
      </SEO>
      <Helmet>
        <meta
          name="robots"
          content={
            siteSEO?.indexStatus === 'noIndex'
              ? 'noindex, nofollow'
              : page?.indexStatus === 'index'
              ? 'index, follow'
              : 'noindex, nofollow'
          }
        />
      </Helmet>
      {/* SECTION: hero */}
      <Section classes="relative flex flex-col place-content-center place-items-center lg:h-screen justify">
        {/* COMPONENT: background image */}
        <div className="absolute inset-0">
          {bgImg
            .filter((item) => item?.name === page?.heroBgImgSlug)
            .map((item) => (
              <Fragment key={item?.id}>
                <GatsbyImage
                  image={item?.childImageSharp?.gatsbyImageData}
                  alt={page?.heroBgImgAlt}
                  loading="eager"
                  className="h-full w-full object-cover"
                />
                <Overlay overlay={hero?.overlay?.color} />
              </Fragment>
            ))}
        </div>
        {/* COMPONENT: 2 column grid */}
        <Grid classes="lg:grid-cols-2 gap-8 max-w-7xl my-6 lg:mb-0 lg:mt-12">
          {/* COMPONENT: text content */}
          <Flex classes="bg-gray-900/60 bg-clipping-path backdrop-filter backdrop-blur-lg mx-4 lg:mx-0 mt-12 lg:mt-24 p-6 lg:p-10 space-y-6 flex-col justify-center relative text-left rounded-xl shadow-xl sm:max-w-md md:max-w-lg lg:max-w-none">
            <StyledHeroHeadline
              ref={ref}
              variants={variants}
              initial="hidden"
              animate={controls}
              transition={{ duration: 0.5, delay: 0 }}
              $headline={hero?.headline?.color}
              className="m-0 text-center text-3xl font-extrabold capitalize tracking-tight sm:text-4xl lg:text-left lg:text-5xl"
            >
              {pageSEO?.title}
            </StyledHeroHeadline>
            <StyledHeroSubheadline
              ref={ref}
              variants={variants}
              initial="hidden"
              animate={controls}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="max-w-3xl space-y-4 text-lg lg:text-xl"
              $subheadline={hero?.tagline?.color}
            >
              <div className="">{`${page?.company} is a ${page?.keyword5} company serving ${page?.audience} in ${page?.cityStateZip}.`}</div>
              <ul className="fa-ul text-left">
                <li className="">
                  <span className="fa-li">
                    <StyledCheckIcon
                      $color={accent.default.color}
                      $colorHover={accent.default.color}
                      className="fas fa-check"
                    ></StyledCheckIcon>
                  </span>
                  <strong className="text-amber-100">
                    Affordable Trash Pickup
                  </strong>{' '}
                  <em>(Weekly, Bi-Weekly, On-Call)</em>
                </li>
                <li className="">
                  <span className="fa-li">
                    <StyledCheckIcon
                      $color={accent.default.color}
                      $colorHover={accent.default.color}
                      className="fas fa-check"
                    ></StyledCheckIcon>
                  </span>
                  <strong className="text-amber-100">Fast Junk Removal</strong>{' '}
                  <em>(Single/Multi-Item)</em>
                </li>
                <li className="">
                  <span className="fa-li">
                    <StyledCheckIcon
                      $color={accent.default.color}
                      $colorHover={accent.default.color}
                      className="fas fa-check"
                    ></StyledCheckIcon>
                  </span>
                  <strong className="text-amber-100">
                    Bulk Junk Cleanouts
                  </strong>{' '}
                  <em>(Garages, Estates, Foreclosures)</em>
                </li>
                <li className="">
                  <span className="fa-li">
                    <StyledCheckIcon
                      $color={accent.default.color}
                      $colorHover={accent.default.color}
                      className="fas fa-check"
                    ></StyledCheckIcon>
                  </span>
                  <strong className="text-amber-100">
                    Demolition & Debris Cleanup
                  </strong>{' '}
                  <em>(Any Material)</em>
                </li>
              </ul>
              <p className="italic">
                Call{' '}
                <AnchorText type="external" href={`tel:${page?.phoneNumber}`}>
                  {page?.phoneNumber}
                </AnchorText>{' '}
                or fill out our form to get started!
              </p>
            </StyledHeroSubheadline>
          </Flex>
          {/* COMPONENT: contact form */}
          <Flex classes="flex-col justify-center relative mx-auto lg:max-w-lg mt-6 lg:mt-24">
            <div className="overflow-hidden">
              <FormHero formName="form-hero" />
            </div>
          </Flex>
        </Grid>
      </Section>
      {/* SECTION: reviews */}
      <Section
        padding="lg"
        bgColor={neutral?.light?.color}
        h2Color={neutral?.darker?.color}
        h3Color={primary?.dark?.color}
        $spanColor={accent?.light?.color}
      >
        <Container classes="text-center">
          <StyledTagline
            $spanColor={accent?.dark?.color}
            className="text-base font-semibold uppercase tracking-wider"
          >
            {page?.reviewHeaderTagline}
          </StyledTagline>
          <h2 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-xl">
            {page?.reviewHeaderHeadline}
          </h2>
          <div className="mx-auto mt-6 max-w-prose text-xl text-zinc-600">
            {page?.reviewHeaderParagraph}
          </div>
          <Container padding="none" classes="pt-12 lg:pt-24">
            <Swiper
              slidesPerView={2}
              directions="horizontal"
              spaceBetween={30}
              loop={true}
              loopedSlides={0}
              slideClass="swiper-slide"
              pagination={{
                bulletActiveClass: 'swiper-pagination-bullet-active',
                clickable: true,
              }}
              speed={5000}
              centeredSlides={true}
              autoplay={{
                delay: 1,
                disableOnInteraction: true,
              }}
              modules={[Pagination, Autoplay]}
              className="relative flex h-full w-full items-center justify-center"
            >
              <SwiperSlide className="lg:mb-4">
                {reviewImg1
                  .filter((item) => item?.name === page?.reviewImg1Slug)
                  .map((item) => (
                    <Fragment key={item?.id}>
                      <GatsbyImage
                        image={item?.childImageSharp.gatsbyImageData}
                        alt={page?.reviewImg1Alt}
                        className="aspect-w-12 aspect-h-7 h-full w-full object-cover"
                      />
                    </Fragment>
                  ))}
              </SwiperSlide>
              <SwiperSlide className="lg:mb-4">
                {reviewImg2
                  .filter((item) => item?.name === page?.reviewImg2Slug)
                  .map((item) => (
                    <Fragment key={item?.id}>
                      <GatsbyImage
                        image={item?.childImageSharp.gatsbyImageData}
                        alt={page?.reviewImg2Alt}
                        className="aspect-w-12 aspect-h-7 h-full w-full object-cover"
                      />
                    </Fragment>
                  ))}
              </SwiperSlide>
              <SwiperSlide className="lg:mb-4">
                {reviewImg3
                  .filter((item) => item?.name === page?.reviewImg3Slug)
                  .map((item) => (
                    <Fragment key={item?.id}>
                      <GatsbyImage
                        image={item?.childImageSharp.gatsbyImageData}
                        alt={page?.reviewImg3Alt}
                        className="aspect-w-12 aspect-h-7 h-full w-full object-cover"
                      />
                    </Fragment>
                  ))}
              </SwiperSlide>
            </Swiper>
          </Container>
        </Container>
      </Section>
      {/* SECTION: residential services */}
      <Section
        padding="lg"
        bgColor={neutral?.lighter?.color}
        h2Color={neutral?.darker?.color}
        h3Color={primary?.dark?.color}
        $spanColor={accent?.light?.color}
      >
        <Container classes="text-center">
          <StyledTagline
            $spanColor={accent?.dark?.color}
            className="text-base font-semibold uppercase tracking-wider"
          >
            {page?.residentialHeaderTagline}
          </StyledTagline>
          <h2 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-xl">
            {page?.residentialHeaderHeadline}
          </h2>
          <div className="mx-auto mt-6 max-w-prose text-xl text-zinc-600">
            {page?.residentialHeaderParagraph}
          </div>
          <Grid classes="mt-20 gap-3 grid-cols-2 lg:grid-cols-4">
            {services
              .filter((service) => service?.tag?.title === 'residential')
              .map((service) => (
                <AnchorText to={service?.link?.slug?.current}>
                  <Col
                    key={service?.name}
                    classes="pt-6 relative w-full mx-auto px-4 sm:px-6 lg:px-8"
                  >
                    <Container padding="none" classes="">
                      <div className="h-full w-full rounded-full bg-white object-cover shadow-2xl transition hover:scale-105">
                        <div className="rounded-full">
                          <GatsbyImage
                            image={service?.image?.asset?.gatsbyImageData}
                            className="aspect-w-12 aspect-h-12 mx-auto h-full w-full rounded-full border-2 border-amber-400 object-cover"
                            alt={service?.image?.alt}
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </Container>
                    <div className="break-word relative z-10 text-center">
                      <h3 className="mt-8 text-xl font-medium lg:text-2xl">
                        {service?.name}
                      </h3>
                      <p className="mt-5 mb-8 text-left text-base text-zinc-500">
                        <SanityBlockContent blocks={service?._rawDescription} />
                      </p>
                    </div>
                  </Col>
                </AnchorText>
              ))}
          </Grid>
        </Container>
      </Section>
      {/* SECTION: residential details */}
      <Section padding="lg" bgColor={neutral?.white?.color}>
        <div className="mx-auto max-w-7xl">
          <div className="grid grid-cols-1 gap-x-24 lg:grid-cols-12">
            <div className="lg:col-span-7">
              <article className="sanity-headlines space-y-6 px-4 text-lg lg:px-0">
                <h2>Residential Garbage Collection Services</h2>
                <p>
                  If you're like most homeowners, you probably have a lot of
                  waste that needs to be dealt with on a regular basis.
                </p>
                <p>
                  That's where Maher Removal & Disposal comes in. We are a waste
                  management company that specializes in{' '}
                  <AnchorText to="residential">
                    residential trash pickup services
                  </AnchorText>
                  .
                </p>
                <p>
                  We offer a variety of solutions to meet your specific needs,
                  including:
                </p>

                <ul className="fa-ul text-left">
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Trash Pickup & Recycling:</strong> Weekly,
                    Bi-Weekly, On-Call
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Junk Removal:</strong> Single/Multi-Item Pickups
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Bulk Junk Cleanouts:</strong> Estates, Garages,
                    Basements, etc.
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Demolition & Debris Cleanup:</strong> Sheds, Decks,
                    Patios, etc.
                  </li>
                </ul>

                <p>
                  No matter what your situation is, we can help you find a
                  solution that works for you.
                </p>

                <p>
                  So if you're looking for a reliable, affordable, and
                  professional waste management company to take care of your
                  residential needs, look no further than Maher Removal &
                  Disposal.
                </p>
                <h2>Get Your Free Estimate</h2>
                <p>Ready for our residential waste management services?</p>
                <p>
                  Call us today or fill out our form for your FREE estimate.
                  We'll be happy to answer any of your questions and help you
                  get started.
                </p>
              </article>
            </div>
            <div className="lg:col-span-5 lg:h-full lg:w-full">
              {/* <Sidebar sidebarType={sidebarType} /> */}
              <aside className="hidden pt-12 lg:flex lg:h-full lg:pt-0">
                <FadeIn classes="space-y-20 w-full lg:h-full">
                  {/* <FormHero formName="form-sidebar" /> */}
                  {/* <SidebarListServices /> */}
                  <div className="space-y-6 lg:sticky lg:top-44">
                    <StyledContainer
                      padding="none"
                      bgColor={primary?.dark?.color}
                      h2Color={accent?.default?.color}
                      h3Color={neutral?.lighter?.color}
                      h3ColorHover={accent?.default?.color}
                      spanColor={accent?.default?.color}
                      outerClasses="rounded-xl shadow-xl"
                      classes="relative p-3 lg:p-5 max-w-4xl mx-3 lg:mx-auto space-y-6"
                    >
                      <h2 className="text-center font-bold text-white">
                        Our Reviews
                      </h2>
                      <iframe
                        id="msgsndr_reviews"
                        src="https://services.leadconnectorhq.com/appengine/reviews/get_widget/JKpErocbga3UXmSNuPLq"
                        frameborder="0"
                        scrolling="no"
                        className="w-full rounded-xl"
                      ></iframe>
                    </StyledContainer>
                  </div>
                </FadeIn>
              </aside>
            </div>
          </div>
        </div>
      </Section>
      {/* SECTION: commercial services */}
      <Section
        padding="lg"
        bgColor={neutral?.lighter?.color}
        h2Color={neutral?.darker?.color}
        h3Color={primary?.dark?.color}
        $spanColor={accent?.light?.color}
      >
        <Container classes="text-center">
          <StyledTagline
            $spanColor={accent?.dark?.color}
            className="text-base font-semibold uppercase tracking-wider"
          >
            {page?.commercialHeaderTagline}
          </StyledTagline>
          <h2 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-xl">
            {page?.commercialHeaderHeadline}
          </h2>
          <div className="mx-auto mt-6 max-w-prose text-xl text-zinc-600">
            {page?.commercialHeaderParagraph}
          </div>
          <Grid classes="mt-20 gap-3 grid-cols-2 lg:grid-cols-4">
            {services
              .filter((service) => service?.tag?.title === 'commercial')
              .map((service) => (
                <AnchorText to={service?.link?.slug?.current}>
                  <Col
                    key={service?.name}
                    classes="pt-6 relative w-full mx-auto px-4 sm:px-6 lg:px-8"
                  >
                    <Container padding="none" classes="">
                      <div className="h-full w-full rounded-full bg-white object-cover shadow-2xl transition hover:scale-105">
                        <div className="rounded-full">
                          <GatsbyImage
                            image={service?.image?.asset?.gatsbyImageData}
                            className="aspect-w-12 aspect-h-12 mx-auto h-full w-full rounded-full border-2 border-amber-400 object-cover"
                            alt={service?.image?.alt}
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </Container>
                    <div className="break-word relative z-10 text-center">
                      <h3 className="mt-8 text-xl font-medium lg:text-2xl">
                        {service?.name}
                      </h3>
                      <p className="mt-5 mb-8 text-left text-base text-zinc-500">
                        <SanityBlockContent blocks={service?._rawDescription} />
                      </p>
                    </div>
                  </Col>
                </AnchorText>
              ))}
          </Grid>
        </Container>
      </Section>
      {/* SECTION: commercial details */}
      <Section padding="lg" bgColor={neutral?.white?.color}>
        <div className="mx-auto max-w-7xl">
          <div className="grid grid-cols-1 gap-x-24 lg:grid-cols-12">
            <div className="lg:col-span-7">
              <article className="sanity-headlines space-y-6 px-4 text-lg lg:px-0">
                <h2>Commercial Garbage Collection Services</h2>
                <p>
                  If you're a business owner in the local area, then you know
                  that waste management can be a huge challenge.
                </p>
                <p>
                  Not only is it important to comply with state and federal
                  regulations, but you also need to ensure that your waste is
                  disposed of in a way that's safe and environmentally friendly.{' '}
                </p>
                <p>
                  That's where Maher Removal & Disposal comes in. We are a waste
                  management company that specializes in{' '}
                  <AnchorText to="commercial">
                    commercial trash pickup services
                  </AnchorText>
                  .
                </p>
                <p>
                  We offer a variety of comprehensive solutions designed to make
                  your life easier, including:
                </p>

                <ul className="fa-ul text-left">
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Business Solid Waste & Recycling:</strong> Weekly,
                    Bi-Weekly, On-Call
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Junk Removal:</strong> Single/Multi-Item Pickups
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Bulk Junk Cleanouts:</strong> Offices, Buildings,
                    Foreclosures, etc.
                  </li>
                  <li className="">
                    <span className="fa-li">
                      <StyledCheckIcon
                        $color={accent.default.color}
                        $colorHover={accent.default.color}
                        className="fas fa-check"
                      ></StyledCheckIcon>
                    </span>
                    <strong>Demolition & Debris Cleanup:</strong> Building
                    Interiors; Any Material
                  </li>
                </ul>

                <p>
                  No matter what your commercial waste disposal needs are, we
                  can help.
                </p>

                <p>
                  Our team of experienced professionals will work with you to
                  develop a customized waste management plan that meets your
                  unique requirements.
                </p>
                <p>
                  Plus, we offer competitive rates that are designed to fit
                  within your budget and flexible scheduling to meet the needs
                  of your business.
                </p>
                <p>
                  So if you're looking for a hassle-free solution to your
                  commercial waste management needs, then Maher Removal &
                  Disposal is the perfect choice for you.
                </p>
                <h2>Get Your Free Estimate</h2>
                <p>Ready for our commercial waste management services?</p>
                <p>
                  Call us today or fill out our form for your FREE estimate.
                  We'll be happy to answer any of your questions and help you
                  get started.
                </p>
              </article>
            </div>
            <div className="lg:col-span-5 lg:h-full lg:w-full">
              {/* <Sidebar sidebarType={sidebarType} /> */}
              <aside className="hidden pt-12 lg:flex lg:h-full lg:pt-0">
                <FadeIn classes="space-y-20 w-full lg:h-full">
                  {/* <SidebarListServices /> */}
                  <div className="space-y-6 lg:sticky lg:top-44">
                    <FormHero formName="form-sidebar" />
                    {/* <StyledContainer
                      padding="none"
                      bgColor={primary?.dark?.color}
                      h2Color={accent?.default?.color}
                      h3Color={neutral?.lighter?.color}
                      h3ColorHover={accent?.default?.color}
                      spanColor={accent?.default?.color}
                      outerClasses="rounded-xl shadow-xl"
                      classes="relative p-3 lg:p-5 max-w-4xl mx-3 lg:mx-auto space-y-6"
                    >
                      <h2 className="text-center text-white font-bold">
                        Our Reviews
                      </h2>
                      <iframe
                        id="msgsndr_reviews"
                        src="https://services.leadconnectorhq.com/appengine/reviews/get_widget/JKpErocbga3UXmSNuPLq"
                        frameborder="0"
                        scrolling="no"
                        className="w-full rounded-xl"
                      ></iframe>
                    </StyledContainer> */}
                  </div>
                </FadeIn>
              </aside>
            </div>
          </div>
        </div>
      </Section>
      <Section padding="lg" bgColor={neutral?.light?.color}>
        <Container classes="text-center mx-auto">
          <div className="pb-24">
            <StyledTagline
              $spanColor={accent?.dark?.color}
              className="text-base font-semibold uppercase tracking-wider"
            >
              {page?.company}
            </StyledTagline>
            <h2 className="mx-auto mt-2 text-3xl font-extrabold tracking-tight sm:text-4xl lg:max-w-xl">
              {page?.keyword1} & {page?.keyword2} Services in{' '}
              {page?.cityStateZip}
            </h2>
            {/* <div className="mt-6 max-w-prose mx-auto text-xl text-zinc-600">
              TEST
            </div> */}
          </div>
          <iframe
            src={page?.mapEmbed}
            width="100%"
            height="450"
            loading="eager"
            name={`${page?.company} is a ${page?.keyword1} company in ${page?.cityState}`}
          ></iframe>
        </Container>
      </Section>
    </Layout>
  );
};

export default LocationTemplate;
